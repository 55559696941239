

.errpagediv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: calc(100vw - (100vw - 100%));
    height: 100vh;

}
.errpagegoback {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.1rem;
    color: rgb(30, 80, 216);
    gap: 2px;
}