


.userinfo {
    position: relative;
    box-sizing: border-box;
    padding:30px 0;
    width: calc(100vw - (100vw - 100%));
    height: calc(100vh - (100vh - 100%));
    
}
.userinfohead {
    text-align: center;
}
.userinfodetails {
    box-sizing:border-box;
    border: 2px solid black;
    border-radius: 15px;
    margin-top: 30px;
    margin-left: 8px;
    margin-right: 8px;
    
}

.userinfoedit {
    width: calc(100vw - (100vw - 100%));
    display: flex;
    flex-direction: row;
   
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 10px;

}

.infoeditbuttons {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.usereditbutton {
    background-color: white;
    color: rgb(21, 166, 244);
    border: 1px solid rgb(21, 166, 244);
    
    padding: 5px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}
.usereditbutton:hover {
    background-color: rgb(21, 166, 244);
    color: white;
    border: none;
}
.editdetailsdiv {
    position: relative;
   
    
}

.sheethead{
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 10px 10px;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
    gap: 5px;
    
    z-index: 1;
}
.sheetbody {
    padding: 20px;
    
}
.cancelbuttonsheet {
    background-color: rgb(241, 238, 238);
    color: red;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.sheetinput {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    
    width: 70%;
    max-width: 400px;
    max-height: 100px;
    
}
.sheetinput input {
    height: 40px;
    width: 100%;
    max-width: 400px;
    max-height: 100px;
    

    
}
.sheetinput span {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
    color: #515050;
}
.submitbtnsheet {
    margin-top: 25px;
    width: max-content;
    background-color: green;
    color: white;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.leaveinfocard {
    background-color: rgb(213, 235, 247);
    border: 1px solid #ccc;
    border-radius: 5px;
    width: max-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 80px;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
.remainleavehead {
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}
.leaveinfocard p {
    line-height: 2;
}
.userinfogrid {
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    gap: 20px 10px;
}
.userinfogrid p {
    max-width: 300px;
    min-width: 50px;
    word-wrap: break-word;
}
.userinfogridtitle{
    display: inline;
    font-size: 1rem;
    font-weight: bold;
}

.passcontainerinuserinfo {
    position: relative;
    

}

.oldpasserrorshow {
    color: red;
    font-size: 0.8rem;
    padding: 5px 0;
    text-align: end;
}











