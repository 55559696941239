


.adminpanels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Adjust grid item widths as needed */
  grid-auto-rows: 240px;
    gap: 10px;
    padding: 10px;
}
.admnav {
    text-decoration: none;
    color: black;
}

.adminpanelelement {
    background-color: rgb(242, 244, 242);
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}
.adminpanelelement:hover {
    background-color:#ddd;
}
.panelcircle {
    
    height: 90px;
    width: 90px;
    border-radius: 50%;
}
.paneltitle {
    text-align:center;
    height: 3rem;
    font-size: 1.1rem;
    font-weight: normal;
}










