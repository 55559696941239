



.empmnthsum {
    margin: 0px 10px 20px 10px;
}

.empmnthfilter {
    padding: 10px;
    background-color: #edebeb;
    border-radius: 5px;
}


.monthpickerempmnth {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid grey;
    cursor: pointer;
    margin-top: 30px;
}


.empsearchmonthsum {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 10px 0px;
}
.empsearchmonthsum span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.empsearchmonthsum:first-child button {
    margin: auto;
}


.searchbtnempmonthsum {
    margin-top: 15px;
    padding: 6px 20px;
    background-color: rgb(138, 212, 25);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.searchbtnempmonthsum:not([disabled]):hover {
    background-color: rgb(103, 164, 12);
}

.nonefoundadmmonth {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.monthsumdetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin-top: 5px;
    padding: 10px 0;
    
}

.pdfbtnallemp {
    margin-top: 5px;
    padding: 6px 20px;
    background-color: rgb(46, 122, 222);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.pdfbtnallemp:hover {
    background-color: rgb(22, 100, 202);
}


.monthsumempsinglecontainer {
    margin-top: 10px;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    border: 1px solid #ccc;
    
}
.monthsumempsinglecontainerhead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
}







.topdetailsmonthsum {
    margin-top: 0px;
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
}
.userdetailsmonthsum {
    display: flex;
    gap: 10px;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.userinfomonthsum {
    flex: 1;
}
.atndncinfomonthsum {
    width: 150px;
}

.atndncinfochildmonthsum p {
    font-size: 0.9rem;
}

.atndncinfochildhighlightmonthsum {
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    
}
.printwholediv {
    border: 1px solid #ccc;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}
.printdetails {
    
    margin: auto;
    
    display: flex;
    flex-direction: column;

    
    padding: 5px;
    position: relative;
    
    
}
.topline {
    height: 4px;
    border-bottom: 2px solid black;
    margin-bottom: 20px;
}
.printheaddiv,.printdatadiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-right: 1px solid black;
    
    
    
}.printdatadiv {
    border-right: 1px solid black;
    border-bottom: 1px solid black;

}
.printheaddiv p,.printdatadiv p {
    
    border-left: 1px solid black;
    border-top: 1px solid black;
    text-align: center;
    
    word-wrap: break-word;
    padding: 5px 1px;
}
table {
    border-collapse: collapse;
}
.monthsumth {
    border: 1px solid black;
    padding: 1px;
}

.monthsumtr {
    page-break-inside: avoid;
    text-align: center;
    font-size: 0.9rem;
    
}
.printheaddiv p:first-child,.printdatadiv p:first-child,.monthsumth:first-child {
    width: 5%;
}
.printheaddiv p:nth-child(2),.printdatadiv p:nth-child(2),.monthsumth:nth-child(2) {
    width: 15%;
}
.printheaddiv p:nth-child(3),.printdatadiv p:nth-child(3),.monthsumth:nth-child(3) {
    width: 10%;
}
.printheaddiv p:nth-child(4),.printheaddiv p:nth-child(5),.printdatadiv p:nth-child(4),.printdatadiv p:nth-child(5),.monthsumth:nth-child(4),.monthsumth:nth-child(5) {
    width: 15%;
}
.printheaddiv p:nth-child(6),.printheaddiv p:nth-child(7),.printdatadiv p:nth-child(6),.printdatadiv p:nth-child(7),.monthsumth:nth-child(6),.monthsumth:nth-child(7) {
    width: 10%;

}
.printheaddiv p:nth-child(8),.printdatadiv p:nth-child(8),.monthsumth:nth-child(8) {
    width: 20%;

}
.printdeptp {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.printdeptspan {
    font-weight:600;
}
.printmonthp {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight:600;
    text-align: center;

}

#pageFooter:after {
    counter-increment: page;
    content: counter(page);
}

.printbottom {
    padding-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: [first] 1fr [second] 1fr [third] 1fr;
}
.checkby3con {
    display: flex;
    flex-direction: column;
    align-items: center;
    

}
.checkby3con p {
    padding-top: 10px;
    font-size: 0.6rem;
}
.checkby3 {
    
    width: 150px;
    height: 2px;
    
    border-bottom: 2px solid black;
}



.check {
    grid-column-start: first;
}

.cerity {
    grid-column-start: second;
}

.approve {
    grid-column-start: third;
}



.searchprevallemp {
    margin-top: 15px;
    padding: 4px 10px;
    background-color: rgb(16, 142, 72);
    color: white;
    font-size: 0.95rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.searchprevallemp:hover {
    background-color: rgb(6, 118, 56);
}

.paddingbtndivs {
    display: flex;
    gap: 40px;
}

.paddingincallempadd {
    margin-top: 15px;
    padding: 4px 10px;
    background-color: rgb(130, 159, 14);
    color: white;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.paddingincallempadd:hover {
    background-color: rgb(102, 126, 7);
}

.paddingincallempminus {
    margin-top: 15px;
    padding: 4px 10px;
    background-color: rgb(206, 140, 17);
    color: white;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.paddingincallempminus:hover {
    background-color: rgb(189, 129, 18);
}









