.myemplv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 5px 20px 5px;
}



.nonefoundmyemplv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.myemplvrevtabscontainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 40px 0px 40px 0px;
}
.myemplvrevtabscontainer span {
    font-size: 0.8rem;
    font-weight: bolder;
}




.myemplvreviewtabs {
    overflow: hidden;
  border: 1px solid rgba(58, 160, 255, 0.799);
  background-color: #f1f1f1;
  border-radius: 5px;
  height: 30px;
}

.tabbuttonsmyemplvrev {
    background-color: inherit;
  float: left;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  height: 100%;
  width: 95px;
  text-align: center;
  transition: 0.3s;
  font-size: 1rem;
}

.tabbuttonsmyemplvrev:hover {
    background-color: #ddd;
}

.tabbuttonsmyemplvrev.active {
    background-color: rgba(58, 160, 255, 0.799);
}




.inputinmyemplvappscontainer {
    padding: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.inputinmyemplvappscontainer input {
    height: 40px;
    width: 50%;
    max-width: 200px;
    max-height: 100px;

}

.srchbtnmyemplv {
    background-color: rgb(58, 150, 200);
    color: white;
    border: none;
    
    
    padding: 10px 14px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}















.myemplvrevcontainer {
    width: 100%;
    border-radius: 5px;
    border: 1px solid black;
    border-top: none;
}


















