



.todayhead {
    text-align: center;
    padding: 20px 0px 10px 0;
}

.topdetailstoday {
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
    position: relative;
}
.userdetailstoday {
    display: flex;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.userinfotoday {
    position: absolute;
    left: 110px;
    
}











