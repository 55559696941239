.monthlyatndnc {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}
.monthpicker {
    display: flex;
    gap: 15px;
    padding: 5px 10px;
    background-color: rgb(234, 225, 225);
    color: black;
    font-size: 1.2rem;
    border-radius: 5px;
    border: 1px solid grey;
    cursor: pointer;
    margin-top: 30px;
}
.monthtopcon{
    width: 100%;
}
.monthdatepicker {
    margin-bottom: 20px;
}

.topdetailsmonthly {
    margin-top: 0px;
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
}
.userdetailsmonthly {
    display: flex;
    gap: 10px;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.userinfomonthly {
    flex: 1;
}
.atndncinfomonthly {
    width: 150px;
}

.atndncinfochildmonthly p {
    font-size: 0.9rem;
}

.atndncinfochildhighlightmonthly {
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    
}



.atnlistcontainer {
    width: 100%;
    border-bottom: 1px solid #ccc;

}