
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');




* {
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Roboto', sans-serif;


}
:root {
  --HOVERONLIST: rgb(167, 166, 166);
  --ODDBGONLIST: rgb(226, 221, 221);

}



.navbar {
  height: 50px;
  background-color: rgb(58, 92, 217);
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  width: calc(100vw - (100vw - 100%));
  z-index: 1;
  top: 0;
  
}
.navbarmenu {
  padding: 10px;

  height: 50px;
  cursor: pointer;
}
/*

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

*/

input:not([type="file"]), select {
  border-radius: 5px;
  border: 2px solid #acaaaa;
  outline: none;
  padding: 10px;
  transition: all 0.3s;

  
}
/*input:not([readonly])*/
input:not([type="file"]):not([readonly]):focus,select:focus {
  border: 2px solid black;
}
input:read-only {
  border: 2px solid white;
  color: rgb(157, 156, 156);

}

img {
  width: calc(100vw * 0.06);
  height: calc(100vw * 0.06);
  min-height: 70px;
  min-width: 70px;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 50%;
  
  border: 1px solid black;
}


select {
  cursor: pointer;
}


 
button:disabled {
  cursor:default;
  background-color: #b4b1b1;

}

#asteriskspan {
  color: red;
}

img.M {
  background-image: url("/src/assets/images/defaultdp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 5px;
}

img.F {
  background-image: url("/src/assets/images/defaultdpfemale.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 0px;
}




.swal-text {
  background-color: #FEFAE3;
  padding: 17px;
  border: 1px solid #F0E1A1;
  display: block;
  margin: 22px;
  text-align: center;
  color: #61534e;
}


select {
  color: black;
  height: 40px;
}
