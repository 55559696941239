


.calendar {
    margin: 30px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.monthpickdiv {
    margin-bottom: 20px;
}


.calendardetails {
    width: calc(100vw - (100vw - 100%));
    
    border: 1px solid black;
    border-radius: 5px;
}



.calendarhead {
    margin-top: 0px;

    display: flex;
    color: white;
    flex: 1;
    background-color: black;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 5px;
    
    
}

.calendarbody {
    display: flex;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 2px;
    min-height: 40px;
    margin-bottom: 3px;
    border-radius: 0px;
    

}



.calendarbody:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.calendarbody:hover {
    background-color: var(--HOVERONLIST);
}

.calendarheaditem{
    flex:1;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}

.calendarbodyitem{
    flex:1;
    padding: 0 5px;
    
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
}







.calendaractiontab {
    overflow: hidden;
  border: 1px solid rgba(179, 179, 179, 0.799);
  background-color: #f1f1f1;
  border-radius: 5px;
  height: 30px;
  max-width: 150px;
}

.tabbuttonscalendar {
    background-color: inherit;
  float: left;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  height: 100%;
  width: 70px;
  text-align: center;
  transition: 0.3s;
  font-size: 1rem;
}

.tabbuttonscalendar:hover {
    background-color: #ddd;
}

.tabbuttonscalendar.workday {
    background-color: rgba(32, 239, 0, 0.799);
    color: white;
}

.tabbuttonscalendar.holiday {
    background-color: rgba(241, 18, 18, 0.931);
    color: white;
}







