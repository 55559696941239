
.nonefoundmyempmonth {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}




.myempmnthsum {
    margin: 0px 10px 20px 10px;
}

.myempmnthfilter {
    padding: 10px;
    background-color: #edebeb;
    border-radius: 5px;
    
}


.myssmonthpickerempmnth {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid grey;
    cursor: pointer;
    margin-top: 30px;
}


.myempsearchmonthsum {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 10px 0px;
}
.myempsearchmonthsum span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.myempsearchmonthsum:first-child button {
    margin: auto;
}


.myempsearchbtnallemp {
    margin-top: 15px;
    padding: 6px 20px;
    background-color: rgb(138, 212, 25);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.myempsearchbtnallemp:not([disabled]):hover {
    background-color: rgb(103, 164, 12);
}

.myempmonthsumdetails {

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin-top: 5px;
    padding: 10px 0;
    
}

.pdfbtnmyemp {
    margin-top: 5px;
    padding: 6px 20px;
    background-color: rgb(46, 122, 222);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.pdfbtnmyemp:hover {
    background-color: rgb(22, 100, 202);
}


.monthsummyempsinglecontainer {
    margin-top: 10px;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    border: 1px solid #ccc;
    
}
.monthsummyempsinglecontainerhead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
}







.myemptopdetailsmonthsum {
    margin-top: 0px;
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
}
.myempuserdetailsmonthsum {
    display: flex;
    gap: 10px;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.myempuserinfomonthsum {
    flex: 1;
}
.myempatndncinfomonthsum {
    width: 150px;
}

.myempatndncinfochildmonthsum p {
    font-size: 0.9rem;
}

.myempatndncinfochildhighlightmonthsum {
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    
}

