
.allemp {
    padding: 30px 10px 10px 10px;
    position: relative;
}

.filterdivallemp {
    margin-top: 20px;
    padding: 10px 10px 10px 10px;
    height: max-content;
    background-color:white;
    border-radius: 5px;
}
.empsearchallemp {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 10px 0px;
}
.empsearchallemp span {
    font-size: 0.8rem;
    font-weight: bolder;
}


.empstatustabs {
    overflow: hidden;
  border: 1px solid rgba(58, 160, 255, 0.799);
  background-color: #f1f1f1;
  border-radius: 5px;
  height: 30px;
}

.tabbuttonsallemp {
    background-color: inherit;
  float: left;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  height: 100%;
  width: 70px;
  text-align: center;
  transition: 0.3s;
  font-size: 1rem;
}

.tabbuttonsallemp:hover {
    background-color: #ddd;
}

.tabbuttonsallemp.active {
    background-color: rgba(58, 160, 255, 0.799);
}


.searchbtnallemp {
    margin-top: 15px;
    padding: 6px 20px;
    background-color: rgb(138, 212, 25);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.searchbtnallemp:not([disabled]):hover {
    background-color: rgb(103, 164, 12);

}

.nonefoundallemp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.allempdetails {
    overflow: scroll;
    max-height: 500px;
  margin-bottom: 20px;
    
}



.allemphead {
    display: flex;
    position: sticky;
    top: 0;

    color: white;
    background-color: black;
    padding: 5px;
    min-width: max-content;
    
    
}

.allempheaditem{
    width: 150px;
    min-width: 150px;
    text-align: center;
    font-size: 1rem;
    padding: 0 5px;
    
    word-break: break-all;
}

.allempheaditem:first-child {
    width: 70px;
    min-width: 70px;

}

.allempdetailsinside {
    display: flex;

    color: black;
    padding: 5px;
    min-width: max-content;
    
    
}

.allempdetailsinside:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.allempdetailsinside:hover {
    background-color: var(--HOVERONLIST);
}



.allempdetailsitem{
    width: 150px;
    min-width: 150px;
    text-align: center;
    font-size: 1rem;
    padding: 0 5px;
    display: flex;
    align-items: start;
    justify-content: center;
    
    word-break: break-all;
}
.allempdetailsitem:first-child {
    font-weight: bolder;
    width: 70px;
    min-width: 70px;

}








