

.emptl {
    margin: 0 0px 10px 0px;
}


.updatetldiv {
    min-height: 0;
    background-color: rgb(226, 251, 124);
    padding:10px 10px;
    border: none;
    margin: 0px 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    
}

.updatetogglebtn {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: white;
    color: rgb(21, 166, 244);
    border: 1px solid rgb(21, 166, 244);
    
    padding: 5px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.updatetogglebtn:not([disabled]):hover {
    background-color: rgb(21, 166, 244);
    color: white;
    border: 1px solid rgb(21, 166, 244);
}

.updatetlaccor {
    display: flex;
    gap: 15px;
    padding: 15px 0;
    flex-direction: column;
    
    align-items: start;
}

.tlaccorelementinput {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.tlaccorelementinput span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.tlaccorelementinput input {
    width: 150px;
}

.nonefoundadmtl {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.tlempupdatebtn {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: rgb(11, 183, 11);
    color: white;
    border: none;
    
    padding: 8px 20px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.tlempupdatebtn:not([disabled]):hover {
    background-color: green;
    color: white;
    border: none;
}
    


.tlcheckdiv {
    border: 1px solid rgb(229, 226, 226);
    padding: 10px;
}

.searchemptl {
    display: flex;
    gap: 15px;
    padding: 15px 0;
    flex-direction: column;
    
    align-items: start;
}

.searchemptlelementinput {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.searchemptlelementinput span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.searchemptlelementinput input {
    width: 150px;
}

.tlempsearbtn {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: rgb(47, 124, 255);
    color: white;
    border: none;
    
    padding: 8px 20px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.tlempsearbtn:not([disabled]):hover {
    background-color: rgb(14, 92, 228);
    color: white;
    border: none;
}








.emptlhead {

    display: flex;
    color: white;
    flex: 1;
    background-color: black;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 5px;
    
    
}

.emptlheaditem{
    width: 20%;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}

.emptldetails {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(100vw - (100vw - 100%));
    padding: 2px;
    min-height: 30px;
    margin-bottom: 3px;
    border-radius: 2px;
    
    

}


.emptldetails:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.emptldetails:hover {
    background-color: var(--HOVERONLIST);
}


.emptldetailsitem{
    width: 20%;
    margin-left: 1px;
    gap: 5px;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}


.emptldetailsitem:first-child {
    display: flex;
    justify-content: start;
    
}
.emptldetailsitem:first-child {
    text-align: start;
}


.emptldetailsitem>span {
    font-weight: bolder;
    word-break: keep-all;
    
}





@media only screen and (min-width: 600px) {
    .updatetlaccor {
        display: flex;
        gap: 25px;
        padding: 15px 0;
        flex-direction: row;
        
        align-items: end;
    }
    
.searchemptl {
    display: flex;
    gap: 25px;
    padding: 15px 0;
    flex-direction: row;
    
    align-items: end;
}
    
    
    
  }
  

