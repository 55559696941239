

.loginpage {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    min-height: 400px;
    
    background-image: url("/src/assets/images/emsl.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.logincard {
    position: relative;
    background-color: white;
    width: calc(100vw * 0.4);
    max-width: 500px;
    min-width: 270px;
    
    height: 300px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    
}
.loader_login, .loadforupdateadmatnsingle {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;

}
.loginelemscontainer {
    display: flex;
    flex-direction: column;
}

.loginelem {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 200px;
    max-width: 200px;
    margin-top: 20px;
}
.loginelem span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.loginelem input {
    width: 200px;
    padding-right: 35px;
}
.errormsglogin {
    padding-left: 2px;
    margin-top: 5px;
    word-break: break-all;
    font-size: 0.75rem;
    color: red;
}

.loginelembtn {
    display: flex;
    font-size: 1rem;
    gap: 1px;
    background-color: rgb(47, 124, 255);
    color: white;
    border: none;
    
    padding: 6px 13px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.loginelembtn:not([disabled]):hover {
    background-color: rgb(14, 92, 228);
    color: white;
    border: none;
}

.passcontainer {
    position: relative;

}
.passwordvisibilitydiv {
    border: none;
    outline: none;
    background-color: inherit;
    height: 80%;
    width: 30px;

    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 50%;
  transform: translateY(-50%);
}
   


 