.lvapp {
    margin: 30px 10px 10px 10px;

}
.leftlvdayscontainer {
    background-color: rgb(213, 235, 247);
    margin-top: 20px;
    padding: 10px;
    line-height: 1.4rem;
}


.lvformcontainer {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 0px;
   
}
.lvformdetelem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0px 10px 0px;
    position: relative;
}
.lvformdetelem span {
    font-size: 0.8rem;
    font-weight: bolder;
}

.lvformdetelem input {
    width: 300px;
}
.datetext {
    border: 2px solid #acaaaa;
    border-radius: 5px;
    padding: 5px;
    background-color: white;

    color: black;
    font-weight: normal;
    cursor: pointer;

}
.datetext.active {
    border: 2px solid black;
    font-weight: normal;
}
.lvformdetelem textarea {
    padding: 5px;
    border-radius: 5px;
    height: 100px;
    max-height: 100px;
    width: 200px;
    max-width: 500px;
    min-width: max-content;
    resize: none;
    max-lines: 2;
}
.date {
    z-index: 1;
    position: absolute;
    top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    background-color: white;

}
.datepicker {
    border-bottom: 1px solid black;
}

.selectdateinsidebtn {
    border: none;
    outline: none;
    padding: 10px 20px;
    width: max-content;
    margin: 5px 0;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    background-color: #3527d6;
    cursor: pointer;
}


.submitlvself {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: rgb(47, 124, 255);
    color: white;
    border: none;
    margin-top: 10px;
    
    padding: 5px 15px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.submitlvself:not([disabled]):hover {
    background-color: rgb(14, 92, 228);
    color: white;
    border: none;
}




