.searchempdash {
    padding: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.searchempdash input {
    height: 40px;
    width: 40%;
    max-width: 200px;
    max-height: 100px;

}


.srchbtn {
    background-color: rgb(58, 150, 200);
    color: white;
    border: none;
    
    
    padding: 10px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}
.nonefoundadmdash {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.topdetailsempdash {
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
    position: relative;
    gap: 10px;
}
.userdetailsempdash {
    display: flex;
    gap: 10px;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.userimgempdash {
    width: calc(100vw * 0.06);
    height: calc(100vw * 0.06);
    min-height: 70px;
    min-width: 70px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 50%;
    
    border: 1px solid black;
}
.userinfodashboardempdash {
    flex: 1;
    
    
}
.atndncinfoempdash {
    width: 150px;
}

.atndncinfochildempdash p {
    font-size: 0.9rem;
}

.atndncinfochildhighlightempdash {
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    
}

.summarystatempdash {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}
.summarystatempdash p {
    font-size: 1.5rem;
}
.barchartempdash {
    max-width: calc(100vw - (100vw - 100%));
}
.lastHeadingempdash {
    font-size: 1.5rem;
    text-align: center;
    margin: 5px 0;
}

