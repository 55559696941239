
.topdetails {
    border: 5px solid grey;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    width: calc(100vw - (100vw - 100%));
    position: relative;
    gap: 10px;
}
.userdetails {
    display: flex;
    gap: 10px;
    width: calc(100vw - (100vw - 100%) - 150px);
}
.userimg {
    width: calc(100vw * 0.06);
    height: calc(100vw * 0.06);
    min-height: 70px;
    min-width: 70px;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 50%;
    
    border: 1px solid black;
}
.userinfodashboard {
    flex: 1;
    
    
}
.atndncinfo {
    width: 150px;
}

.atndncinfochild p {
    font-size: 0.9rem;
}

.atndncinfochildhighlight {
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    
}

.summarystat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}
.summarystat p {
    font-size: 1.5rem;
}
.barchart {
    max-width: calc(100vw - (100vw - 100%));
}
.lastHeading {
    font-size: 1.5rem;
    text-align: center;
    margin: 5px 0;
}

