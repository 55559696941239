.sidebar{
    
    width: 200px;
    padding-top: 5px;
    
    background-color: black;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0;
    
    overflow: auto;
    font-size: 1rem;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    word-break: keep-all;
    transition: left 0.3s ease-in-out;
    z-index: 2;
    overflow: auto;
    
}

.sidebarlist{
    text-decoration: none;
    text-align: left;
    color: white;
    
    padding-bottom: 0px;
    padding: 15px 5px;
    margin: 0 5px;
}
.active {
    color: white;
    font-weight: 600;
    background-color: rgb(86, 90, 90);
    border-radius: 5px;
}
.admtm {
    color: rgb(30, 238, 238);
}
.sidebar.showsidebar {
   left: 0px;
}
.sidebar.hidesidebar{
    left: -200px;
}
.logout {
    color: rgb(231, 5, 5);
    cursor: pointer;
}
