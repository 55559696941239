





.myemppanels {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 240px)); /* Adjust grid item widths as needed */
  grid-auto-rows: minmax(100px,240px);
    gap: 10px;
    padding: 10px;
}
.myempnav {
    text-decoration: none;
    color: black;
}

.myemppanelelement {
    background-color: rgb(242, 244, 242);
    display: flex;
    height: 100%;

    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}
.myemppanelelement:hover {
    background-color:#ddd;
}
.myemppanelcircle {
    
    height: 90px;
    width: 90px;
    border-radius: 50%;
}
.myemppaneltitle {
    text-align:center;
    height: 3rem;
    font-size: 1.1rem;
    font-weight: normal;
}