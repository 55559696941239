


.editdetailsdivoverlaymodemp {
    background-color: white;
}

.sheetheadmodemp{
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 10px 10px;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
    gap: 5px;
}

.modempdtsheetgridcontainer {

    padding-top: 30px;
    padding-bottom: 30px;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: rgb(245, 245, 245);
    

}
.userinfogridmodempdtsheet {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1,1fr); /* Adjust grid item widths as needed */
  grid-auto-rows: 40px;
    gap: 50px 20px;
    padding: 10px;
    place-items: start;
    
}
.modempdtsheetitem {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    justify-items: start;
    
    
    
}


.modempdtsheetitem input:not([type="file"]), .selectdeptmodempdtsheet {
    width: 200px;
    max-width: 100%;
    min-width: 10%;
    height: 40px;

}
.modempdtsheetitem input[type="file"] {
    width: 150px;
    max-width: 100%;
    min-width: 10%;

}
.modempdtsheetitem span:not([id]) {
    background-color: inherit;
    font-size: 0.8rem;
    font-weight: bolder;
    padding-bottom: 3px;
    padding-left: 5px;
    
}
.modempdtsheetitem.radio {
    width: 300px;
    max-width: 100%;
    min-width: 10%;
    height: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 17px;

    
    

}
.modempdtsheetitem.radio label {
    display: flex;
    align-items: end;
    
    line-height: 1;
}
.modempdtsheetitem.radio input {
    display: flex;
    align-items: end;
    justify-content: end;
}

.onlygenders {
    display: flex;
    gap: 10px;
}

.modempdtsheetitem:last-child {
    height: max-content;
    width: 300px;
    max-width: 100%;
    min-width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    justify-items: start;
}
.modempdtsheetitem:last-child>img {
    height: 80px;
    width: 80px;
    
min-height: 80px;
min-width: 80px;
max-width: 100px;
max-height: 100px;
object-fit: cover;
border-radius: 50%;

border: 1px solid black;
}

.createempbtn {
    background-color: rgb(58, 150, 200);
    color: white;
    border: none;
    margin-top: 60px;
    margin-left: 10px;
    
    
    padding: 10px 20px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}


.modempdtsheetitemimage {
    
    width: 100%;
}



@media only screen and (min-width: 600px) {
      
.modempdtsheetgridcontainer {

    align-items: center;
    

}
    .userinfogridmodempdtsheet {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2,1fr); /* Adjust grid item widths as needed */
      grid-auto-rows: 40px;
        gap: 50px 20px;
        padding: 20px 30px 40px 40px;
        place-items: center;
        
    }
    .modempdtsheetitem input:not([type="file"]),.selectdeptmodempdtsheet {
        width: 200px;
        max-width: 100%;
        min-width: 10%;
        height: 40px;
    
    }
    .modempdtsheetitem:last-child {
        height: max-content;
        width: 200px;
        max-width: 100%;
        min-width: 10%;
    }
    .modempdtsheetitem:last-child>img {
        
        height: 80px;
        width: 80px;
        
  min-height: 80px;
  min-width: 80px;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 50%;
  
  border: 1px solid black;
    }
    
    
  }
  @media only screen and (min-width: 768px) {
    
.modempdtsheetgridcontainer {

    align-items: center;
    

}
    .userinfogridmodempdtsheet {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2,1fr); /* Adjust grid item widths as needed */
      grid-auto-rows: 40px;
        gap: 50px 20px;
        padding: 20px 30px 40px 40px;
        place-items: center;
        
    }
    .modempdtsheetitem input:not([type="file"]),.selectdeptmodempdtsheet {
        width: 200px;
        max-width: 100%;
        min-width: 10%;
        height: 40px;
    
    }
    .modempdtsheetitem:last-child {
        height: max-content;
        width: 200px;
        max-width: 100%;
        min-width: 10%;
    }
    .modempdtsheetitem:last-child>img {
        height: 80px;
        width: 80px;
        
  min-height: 80px;
  min-width: 80px;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 50%;
  
  border: 1px solid black;
    }
   
    /* For desktop: */
    
  }