
.newemp {
   
    box-sizing: border-box;
    padding: 30px 0;
    
    width: calc(100vw - (100vw - 100%));
    height: calc(100vh - (100vh - 100%));
}

.newempgridcontainer {
    margin: 0px 10px 0 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 2px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    

}
.userinfogridnewemp {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1,1fr); /* Adjust grid item widths as needed */
  grid-auto-rows: minmax(40px,auto);
    gap: 50px 20px;
    padding: 20px 30px 40px 40px;
    place-items: center;
    
}
.newempitem {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    justify-items: start;
    
    
}



.newempitem input:not([type="file"]), .selectdeptnewemp {
    width: 300px;
    max-width: 100%;
    min-width: 10%;
    height: 40px;

}
.newempitem span:not([id]) {
    background-color: white;
    font-size: 0.8rem;
    font-weight: bolder;
    padding-bottom: 3px;
    padding-left: 5px;
    
}
.newempitem.radio {
    width: 300px;
    max-width: 100%;
    min-width: 10%;
    height: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 17px;

    
    

}
.newempitem.radio label {
    display: flex;
    align-items: end;
    
    line-height: 1;
}
.newempitem.radio input {
    display: flex;
    align-items: end;
    justify-content: end;
}

.onlygenders {
    display: flex;
    gap: 10px;
}

.newempitem:last-child {
    height: max-content;
    width: 300px;
    max-width: 100%;
    min-width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    justify-items: start;
}

.createempbtn {
    background-color: rgb(58, 150, 200);
    color: white;
    border: none;
    margin-top: 30px;
    
    
    padding: 10px 20px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}


.newempitemimage {
    width: 100%;
}



@media only screen and (min-width: 600px) {
    .userinfogridnewemp {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2,1fr); /* Adjust grid item widths as needed */
      grid-auto-rows: minmax(40px,auto);
        gap: 50px 20px;
        padding: 20px 30px 40px 40px;
        place-items: center;
        
    }
    .newempitem input:not([type="file"]),.selectdeptnewemp {
        width: 220px;
        max-width: 100%;
        min-width: 10%;
        height: 40px;
    
    }
    
.newempitem.radio {
    width: 220px;
    max-width: 100%;
    min-width: 10%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 17px;
}

    
    

    
.newempitem:last-child {
    height: max-content;
    width: 220px;
    max-width: 100%;
    min-width: 10%;
}

    
  }
  @media only screen and (min-width: 768px) {
    .userinfogridnewemp {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3,1fr); /* Adjust grid item widths as needed */
      grid-auto-rows: minmax(40px,auto);
        gap: 50px 20px;
        padding: 20px 30px 40px 40px;
        place-items: center;
        
    }
    .newempitem input:not([type="file"]),.selectdeptnewemp {
        width: calc((100vw - 20px - 40px) *0.25);
        max-width: 100%;
        min-width: 10%;
        height: 40px;
    
    }
    
.newempitem.radio {
    width: calc((100vw - 20px - 40px) *0.25);
    max-width: 100%;
    min-width: 10%;
    height: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 17px;
    background-color: aqua;

    
    

}
 
.newempitem:last-child {
    height: max-content;
    width: calc((100vw - 20px - 40px) *0.25);
    max-width: 100%;
    min-width: 10%;
}
    /* For desktop: */
    
  }