

.modifyempmodemp {
    display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        margin: 10px;
    
}
.searchempmodifyempmodemp {
    padding: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: calc(100vw - (100vw - 100%));
}
.searchempmodifyempmodemp input {
    height: 40px;
    width: 40%;
    max-width: 200px;
    max-height: 100px;

}

.imgholderuserinfomodemp {
    position: relative;

}
.loadimg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

}


.userinfodetailsmodemp {
    width: 100%;
    border: 2px solid black;
    border-radius: 15px;
    margin-top: 30px;
    
}

.userinfoeditmodemp {
    width: calc(100vw - (100vw - 100%));
    display: flex;
    flex-direction: row;
   
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 10px;
    position: relative;

}

.userimguserinfomodemp {
    flex: 1;

    
}

.usereditbuttonmodemp {
    background-color: white;
    color: rgb(21, 166, 244);
    border: 1px solid rgb(21, 166, 244);
    
    padding: 5px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}
.usereditbuttonmodemp:hover {
    background-color: rgb(21, 166, 244);
    color: white;
    border: none;
}

.editandleavesdiv {
    display: flex;
    margin: 10px;
    
    justify-content: space-between;
    gap: 10px;
    
}

.nonefoundadmmod {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.leaveinfocardmodemp {
    background-color: rgb(213, 235, 247);
    border: 1px solid #ccc;
    border-radius: 5px;
    width: max-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 40px;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
.infoeditbuttonsmodemp {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}
.remainleaveheadmodemp {
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}
.leaveinfocardmodemp p {
    line-height: 2;
}
.userinfogridmodemp {
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: start;
    gap: 20px 15px;
}
.userinfogridmodemp p {
    max-width: 400px;
    min-width: 50px;
    word-wrap: break-word;
}
.userinfogridtitlemodemp{
    display: inline;
    font-size: 1rem;
    font-weight: bold;
}
