







.lvreddetails {
    display: flex;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 2px;
    min-height: 30px;
    margin-bottom: 3px;
    border-radius: 2px;
    height: max-content;
    

}

.lvreddetails:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.lvreddetails:hover {
    background-color: var(--HOVERONLIST);
}
.lvreddetailsitem{
    width: 20%;
    text-align: center;
    padding: 2px 5px;
    
    word-break: break-all;
    line-height: 1.5;
}

.lvreddetailsitemcontent {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: start;
    word-break: break-all;
}
.lvreddetailsitemcontent span {
    font-size: 0.8rem;
    font-weight: bolder;
}

.lvreddetailsitemcontent p {
    font-size: 1rem;
}
.lvreddetailsitem:last-child {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.lvacceptrejtabs {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
  
  width: max-content;
  height: max-content;
}

.tabbuttonslvrevacceptrej {
    background-color: inherit;
  padding: 3px 6px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  text-align: center;
  transition: 0.3s;
  font-size: 1rem;
}

.tabbuttonslvrevacceptrej:hover {
    background-color: #ddd;
}

.tabbuttonslvrevacceptrej.accept {
    background-color: rgba(32, 239, 0, 0.799);
    color: white;
}

.tabbuttonslvrevacceptrej.reject {
    background-color: rgba(241, 18, 18, 0.931);
    color: white;
}


.lvreviewedstatusclr {
    height: 10px;
    width: 10px;
    
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
    
}