






.lateabsentchklist {
    margin: 0px;
}
.nonefoundadmday {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}


.updateladivchklist {
    min-height: 0;
    background-color: rgb(226, 251, 124);
    padding:10px 10px;
    margin: 0 0 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    
}

.updatetogglebtnchklist {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: white;
    color: rgb(21, 166, 244);
    border: 1px solid rgb(21, 166, 244);
    
    padding: 5px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.updatetogglebtnchklist:hover {
    background-color: rgb(21, 166, 244);
    color: white;
    border: 1px solid rgb(21, 166, 244);
}
.laupdateaccorchklist {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.atnupdatetopline {
    display: flex;
    align-items: start;
    width: 100%;
    gap: 25px;
    padding: 15px 0;
    
    align-items: end;

}

.laaccorelementinputchklist {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
}
.laaccorelementinputchklist span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.laaccorelementinputchklist input {
    width: 150px;
}
.lachklistupdatedetailssection {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: rgb(240, 245, 249);
    padding-bottom: 10px;
}
.updatedetgridcontainer {
    border-radius: 5px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Adjust grid item widths as needed */
  grid-auto-rows: minmax(40px,auto);
    gap: 5px 20px;
    padding: 20px 30px 40px 40px;
    margin-bottom: 0px;
    place-items:start;

}


.updateinfoelem {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
    padding: 0px 0px 5px 0px;
}
.updateinfoelem span {
    font-size: 0.8rem;
    font-weight: bolder;
}

.laempupdatebtnchklist {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: rgb(11, 183, 11);
    color: white;
    border: none;
    
    padding: 8px 20px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.laempupdatebtnchklist:not([disabled]):hover {
    background-color: green;
    color: white;
    border: none;
}
    


.lacheckdivchklist {
    border: 1px solid rgb(229, 226, 226);
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    
    
    
}


.searchempchklist {
    display: flex;
    

    width: 100%;
    gap: 25px;
    padding: 15px 0;
    
    align-items: end;
    justify-content: start;
}
.monthpickerlachklist {
    gap: 15px;
    padding: 9px 10px;
    background-color: white;
    color: black;
    font-size: 1rem;
    border-radius: 5px;
    border: 2px solid #acaaaa;
    cursor: pointer;
    margin-top: 0px;
    
}
.monthpickerlachklist.active {
    border: 2px solid black;
}


.searchemplaelementinputchklist {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.searchemplaelementinputchklist span {
    font-size: 0.8rem;
    font-weight: bolder;
}
.searchemplaelementinputchklist input {
    width: 150px;
}

.laempsearbtnchklist {
    display: flex;
    font-size: 1rem;
    gap: 15px;
    background-color: rgb(47, 124, 255);
    color: white;
    border: none;
    
    padding: 8px 20px;
    border-radius: 5px;
    width: max-content;

    transition: all 0.3s ease;
    cursor: pointer;
}

.laempsearbtnchklist:not([disabled]):hover {
    background-color: rgb(14, 92, 228);
    color: white;
    border: none;
}


.dailyattnlistcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    border: 1px solid #ccc;


    
}




.emplaheadchklist {

    display: flex;
    color: white;
    flex: 1;
    background-color: black;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 5px;
    
    
    
}

.emplaheaditemchklist{
    flex: 1;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
    
}

.empladetailschklist {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: calc(100vw - (100vw - 100%));
    padding: 2px;
    min-height: 30px;
    margin-bottom: 3px;
    border-radius: 2px;
    
    
    

}
.empladetailschklist:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.empladetailschklist:hover {
    background-color: var(--HOVERONLIST);
}


.empdetailsitemchklist{
   width: 16%;
    margin-left: 1px;
    gap: 5px;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}


.empdetailsitemchklist:first-child {
    display: flex;
    justify-content: start;
    
}

.empdetailsitemchklist>span {
    font-weight: bolder;
    word-break: keep-all;
    
}
.empdetailsitemchklist:first-child {
    text-align: start;
}


.downloadpdflachklist {
    margin-top: 10px;
    background-color: rgb(47, 158, 0);
    color: white;
    border: none;
    
    
    padding: 10px;
    border-radius: 5px;

    transition: all 0.3s ease;
    cursor: pointer;
}


.downloadpdflachklist:hover {
    background-color: rgb(34, 106, 4);

}

.printwholedivlateabsent {
    border: 1px solid #ccc;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 5px;
    width: 100%;
}
.printdetailslateabsent {
    
    margin: auto;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 10px;
    position: relative;
    
    
}
.toplinelateabsent {
    height: 4px;
    width: 100%;
    border-bottom: 2px solid black;
    margin-bottom: 20px;
}

.printdeptplateabsent {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.printstatusspan {
    font-weight:600;
}
.printmonthplateabsent {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight:600;
    text-align: center;

}
.tablecontainerlateabsent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 10px;
    position: relative;
}

.lateabsentprinttable {
    width: 100%;
    border-collapse: collapse;
    
}
.lateabsentprinthead,.lateabsentprintbody {
    border: 1px solid black;
    padding: 1px;
    text-align: center;
    font-size: 0.9rem;
}
.lasteabsentprinttr {
    page-break-inside: avoid;
    text-align: center;
    font-size: 0.9rem;
    
}
.lateabsentprinthead:first-child {
    width: 5%;
}
.lateabsentprinthead:nth-child(2) {
    width: 35%;
}
.lateabsentprinthead:nth-child(3) {
    width: 8%;
}

.lateabsentprinthead:nth-child(4) {
    width: 40%;
}
.lateabsentprinthead:nth-child(5) {
    width: 12%;

}



.loadforupdateadmatnsingle {}
