
.sheetheadmodemp{
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 10px 10px;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid black;
    gap: 5px;
}



.cancelbuttonsheetmodemp {
    background-color: rgb(241, 238, 238);
    color: red;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.sheetinputmodemp {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    
}
.sheetinputmodemp input {
    height: 40px;
    width: 70%;
    max-width: 400px;
    max-height: 100px;
    

    
}
.sheetinputmodemp span {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    font-size: 0.8rem;
    color: #515050;
}
.submitbtnsheetmodemp {
    margin-top: 25px;
    width: max-content;
    background-color: green;
    color: white;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}