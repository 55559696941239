
.lastfewdaysatndnc {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: calc(100vw - (100vw - 100%));
}

.atnhead {

    display: flex;
    color: white;
    flex: 1;
    background-color: black;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 5px;
    
    
}


.atnheaditem{
    width: 20%;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}

.atndetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100vw - (100vw - 100%));
    padding: 2px;
    min-height: 30px;
    margin-bottom: 3px;
    border-radius: 2px;
    

}

.atndetails:nth-child(odd) {
    background-color: var(--ODDBGONLIST);
}
.atndetails:hover {
    background-color: var(--HOVERONLIST);
}
.atndetailsitem{
    width: 20%;
    text-align: center;
    padding: 0 5px;
    
    word-break: break-all;
}
